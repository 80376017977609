<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <h4 class="h5 border-left-primary border-left-3">
          {{ $t("bank_details_intro") }}
        </h4>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Bank Name"
          label-for="hi-first-name"
          label-cols-md="4"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input id="hi-first-name" placeholder="Bank Name" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Bank SWIFT/BIC"
          label-for="hi-first-name"
          label-cols-md="4"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <b-form-input id="hi-first-bswift" placeholder="CTBAAU2S" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="BSB" label-for="hi-number" label-cols-md="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SmartphoneIcon" />
            </b-input-group-prepend>
            <b-form-input id="hi-number" type="number" placeholder="219912" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Account No."
          label-for="hi-number"
          label-cols-md="4"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SmartphoneIcon" />
            </b-input-group-prepend>
            <b-form-input id="hi-number" type="number" placeholder="123456" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- submit and reset -->
      <b-col offset-md="4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
          Save Changes
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
};
</script>